import { graphql } from "gatsby";
import React, { useState } from "react";
import { Accordion, Container, Row, Col, Nav, Tab } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useFlexSearch } from "react-use-flexsearch";
import * as queryString from "query-string";
// import { BsCaretRightFill, BsCaretDownFill } from "react-icons/bs";
import ContextAwareToggle from "../../components/ContextAwareToggle";
import SEO from "../../components/SEO";

const SearchFAQs = ({ localSearchFaqs, location, faqs_about_appvity, faqs_etask, faqs_ehelpdesk, 
    // faqs_subscription 
}) => {
    const { search } = queryString.parse(location.search)
    const [query, setQuery] = useState(search || "")

    const results = useFlexSearch(
        query, 
        localSearchFaqs.index, 
        localSearchFaqs.store
    )

    function disableSearchButton () {
        document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-icon-close").style.display = "block";
        var check = document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-search-input");
        if (check.value.length === 0) {
            document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-search-btn").style.display = "block";
            document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-icon-close").style.display = "none";
        } else {
            document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-search-btn").style.display = "none";
        }
    }

    function clearDataSearch() {
        document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-search-input").value = "";
        document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-search-btn").style.display = "block";
        document.querySelector(".app-how-can-we-help-you .app-how-can-we-help-you-detail .app-how-can-we-help-you-detail-search .app-how-can-we-help-you-detail-icon-close").style.display = "none";
    }
  
    return (
      <>
        <div className="app-how-can-we-help-you-detail-search">
            <input 
                id="search"
                className="app-how-can-we-help-you-detail-search-input" 
                placeholder="Ask a question" 
                value={query}
                onChange={e => {
                    setQuery(e.target.value)
                    disableSearchButton()
                }}
            />
            <input 
                className="app-how-can-we-help-you-detail-search-btn" 
                type="button" 
                value="Search" 
            />
            <img className="app-how-can-we-help-you-detail-icon-search" src="/app-icon-search.svg" alt="app icon search"/>
            <input 
                className="app-how-can-we-help-you-detail-icon-close" 
                type="button" 
                onClick={e => {
                    setQuery(e.target.value)
                    clearDataSearch()
                }}
            />
        </div>
        <div className="app-how-can-we-help-you-search-results">
            {query ? (
                results.length > 0 ? (
                    <FAQsKeywordSearch
                        results={results}
                    />
                ) : (
                    <Row>
                        <Col xs={12} md={12} lg={2}></Col>
                        <Col xs={12} md={12} lg={8}>
                            <h6 className="app-no-results">
                                Sorry, couldn't find any posts matching this search.
                            </h6>
                        </Col>
                        <Col xs={12} md={12} lg={2}></Col>
                    </Row>
                )
            ) : (
                <FAQSAllQuestions
                    faqs_about_appvity={faqs_about_appvity}
                    // faqs_etask={faqs_etask}
                    // faqs_ehelpdesk={faqs_ehelpdesk}
                    // faqs_subscription={faqs_subscription}
                />
            )}
        </div>
       </>
    )
}

const FAQsKeywordSearch = ({ results }) => 
    results.map(node => {
        const title = node.title || node.slug
        const id = node.id
        const html = node.html

        return (
            <Row className="app-how-can-we-help-you-search-results-row-custom">
                <Col xs={12} md={12} lg={2}></Col>
                <Col xs={12} md={12} lg={8}>
                    <Accordion className="app-how-can-we-help-you-detail-content" key={id} defaultActiveKey={id}>
                        <div className="app-how-can-we-help-you-detail-content-header">
                            <ContextAwareToggle
                                eventKey={id}
                            >
                                <h6>{title}</h6>
                            </ContextAwareToggle>
                        </div>
                        <Accordion.Collapse eventKey={id}>
                            <div className="app-how-can-we-help-you-detail-content-body"
                                dangerouslySetInnerHTML={{
                                    __html: html
                                }}
                            />
                        </Accordion.Collapse>
                    </Accordion>
                </Col>
                <Col xs={12} md={12} lg={2}></Col>
            </Row>
        )
    }
)

const FAQSAllQuestions = ({ faqs_about_appvity, faqs_etask, faqs_ehelpdesk, 
    // faqs_subscription 
}) => {
    return (
        <Tab.Container id="left-tabs-example" defaultActiveKey="about-appvity">
            <Row>
                {/* <Col xs={12} md={12} lg={2}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                            <Nav.Link eventKey="about-appvity"><h5>About Appvity</h5></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="etask"><h5>eTask</h5></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="ehelpdesk"><h5>eHelpdesk</h5></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="subscription"><h5>Subscription</h5></Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Col> */}
                <Col className="app-how-can-we-help-you-detail-col-custom" xs={12} md={12} lg={12}>
                    <Tab.Content>
                        <Tab.Pane eventKey="about-appvity">
                            {faqs_about_appvity.map((res) => (
                                // defaultActiveKey={1} => because the MD file that has keyNumber = 1 always show first when load page
                                <Accordion className="app-how-can-we-help-you-detail-content" key={res.id} defaultActiveKey={1}>
                                    <div className="app-how-can-we-help-you-detail-content-header">
                                        <ContextAwareToggle
                                            eventKey={res.frontmatter.keyNumber}
                                        >
                                            <h6>{res.frontmatter.title}</h6>
                                        </ContextAwareToggle>
                                    </div>
                                    <Accordion.Collapse eventKey={res.frontmatter.keyNumber}>
                                        <div className="app-how-can-we-help-you-detail-content-body"
                                            dangerouslySetInnerHTML={{
                                                __html: res.html
                                            }}
                                        />
                                    </Accordion.Collapse>
                                </Accordion>
                            ))}
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="etask">
                            {faqs_etask.map((res) => (
                                // defaultActiveKey={1} => because the MD file that has keyNumber = 1 always show first when load page
                                <Accordion className="app-how-can-we-help-you-detail-content" key={res.id} defaultActiveKey={1}>
                                    <div className="app-how-can-we-help-you-detail-content-header">
                                        <ContextAwareToggle
                                            eventKey={res.frontmatter.keyNumber}
                                        >
                                            <h6>{res.frontmatter.title}</h6>
                                        </ContextAwareToggle>
                                    </div>
                                    <Accordion.Collapse eventKey={res.frontmatter.keyNumber}>
                                        <div className="app-how-can-we-help-you-detail-content-body"
                                            dangerouslySetInnerHTML={{
                                                __html: res.html
                                            }}
                                        />
                                    </Accordion.Collapse>
                                </Accordion>
                            ))}
                        </Tab.Pane>
                        <Tab.Pane eventKey="ehelpdesk">
                            {faqs_ehelpdesk.map((res) => (
                                // defaultActiveKey={1} => because the MD file that has keyNumber = 1 always show first when load page
                                <Accordion className="app-how-can-we-help-you-detail-content" key={res.id} defaultActiveKey={1}>
                                    <div className="app-how-can-we-help-you-detail-content-header">
                                        <ContextAwareToggle
                                            eventKey={res.frontmatter.keyNumber}
                                        >
                                            <h6>{res.frontmatter.title}</h6>
                                        </ContextAwareToggle>
                                    </div>
                                    <Accordion.Collapse eventKey={res.frontmatter.keyNumber}>
                                        <div className="app-how-can-we-help-you-detail-content-body"
                                            dangerouslySetInnerHTML={{
                                                __html: res.html
                                            }}
                                        />
                                    </Accordion.Collapse>
                                </Accordion>
                            ))}
                        </Tab.Pane> */}
                        {/* <Tab.Pane eventKey="subscription">
                            {faqs_subscription.map((res) => (
                                // defaultActiveKey={1} => because the MD file that has keyNumber = 1 always show first when load page
                                <Accordion className="app-how-can-we-help-you-detail-content" key={res.id} defaultActiveKey={1}>
                                    <div className="app-how-can-we-help-you-detail-content-header">
                                        <ContextAwareToggle
                                            eventKey={res.frontmatter.keyNumber}
                                        >
                                            <h6>{res.frontmatter.title}</h6>
                                        </ContextAwareToggle>
                                    </div>
                                    <Accordion.Collapse eventKey={res.frontmatter.keyNumber}>
                                        <div className="app-how-can-we-help-you-detail-content-body"
                                            dangerouslySetInnerHTML={{
                                                __html: res.html
                                            }}
                                        />
                                    </Accordion.Collapse>
                                </Accordion>
                            ))}
                        </Tab.Pane> */}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

class Faqs extends React.Component {

    // componentDidMount() {
	// 	const { location } = this.props;

    //     var host_website = location.host ? location.host : "";
    //     var new_online_doc_url;

    //     if (host_website === "localhost:8000" || host_website === "127.0.0.1:8000") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "protoweb.appvity.com:8082") {
    //         new_online_doc_url = "http://protoweb.appvity.com:8083/";
    //     } else if (host_website === "vmweb:8082") {
    //         new_online_doc_url = "http://vmweb:8083/";
    //     } else if (host_website === "10.8.30.46:8082") {
    //         new_online_doc_url = "http://10.8.30.46:8083/";
    //     } else if (host_website === "10.9.9.104:8082") {
    //         new_online_doc_url = "http://10.9.9.104:8083/";
    //     } else {
    //         new_online_doc_url = "https://www.appvity.com:8083/";
    //     }

    //     document.querySelector(".app-online-documentation-answer .app-online-documentation-answer-detail .app-online-documentation-answer-detail-col-custom .app-online-documentation-answer-detail-content a.app-online-documentation-answer-detail-content-etask").setAttribute("href", new_online_doc_url + "odoc/eTask-User-Guide/Introduction/01-00-introduction.html");
    //     document.querySelector(".app-online-documentation-answer .app-online-documentation-answer-detail .app-online-documentation-answer-detail-col-custom .app-online-documentation-answer-detail-content a.app-online-documentation-answer-detail-content-ehelpdesk").setAttribute("href", new_online_doc_url + "odoc/07-00-00.html");
    //     document.querySelector(".app-online-documentation-answer .app-online-documentation-answer-detail .app-online-documentation-answer-detail-col-custom .app-online-documentation-answer-detail-content a.app-online-documentation-answer-detail-content-powershell").setAttribute("href", new_online_doc_url + "odoc/09-00-00.html");
    //     document.querySelector(".app-online-documentation-answer .app-online-documentation-answer-detail .app-online-documentation-answer-detail-col-custom .app-online-documentation-answer-detail-content a.app-online-documentation-answer-detail-content-rest-api").setAttribute("href", new_online_doc_url + "odoc/eTask_Rest_API_Document/08-03-01.html");
	// }

    render() {
        const { data, location } = this.props;
        const localSearchFaqs = data.localSearchFaqs;
        const localSearchFaqsvi = data.localSearchFaqsvi;
        const faqs_about_appvity = data.allMarkdownRemark.nodes;
        const faqs_etask = data.faqs_etask.nodes;
        const faqs_ehelpdesk = data.faqs_ehelpdesk.nodes;
        const faqs_about_appvity_vi = data.faqs_about_appvity_vi.nodes;
        const faqs_etask_vi = data.faqs_etask_vi.nodes;
        const faqs_ehelpdesk_vi = data.faqs_ehelpdesk_vi.nodes;
        // const faqs_subscription = data.faqs_subscription.nodes;
        // const faqs_subscription_vi = data.faqs_subscription_vi.nodes;
        const faqs_onlinedoc_have_answer = data.faqs_onlinedoc_have_answer.nodes;
        // const faqs_related_article_ms = data.faqs_related_article_ms.nodes;
        const faqs_you_have_question = data.faqs_you_have_question.nodes;
        const faqs_you_have_question_vi = data.faqs_you_have_question_vi.nodes;
  
      return (
            <Layout>
                <SEO title="FAQs" description="Appvity FAQs for more knowledge about eProducts and company information." meta={[{ name: `keywords`, content: ["Appvity FAQs", "task management"] }]} pathname="/support/faqs" />
                <div className="main-content-padding">
                    <div className="app-faqs-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-data-en">
                                <h1>Frequently Asked Questions</h1>
                            </div>
                            <div className="app-data-vi">
                                <h1>Frequently Asked Questions</h1>
                            </div>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-how-can-we-help-you" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-data-en app-title">
                                        <h2>Hi! How can we help you?</h2>
                                    </div>
                                    <div className="app-data-vi app-title">
                                        <h2>Hi! How can we help you?</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-how-can-we-help-you-detail">
                                <div className="app-data-en">
                                    <SearchFAQs 
                                        localSearchFaqs={localSearchFaqs}
                                        location={location}
                                        faqs_about_appvity={faqs_about_appvity}
                                        faqs_etask={faqs_etask}
                                        faqs_ehelpdesk={faqs_ehelpdesk}
                                        // faqs_subscription={faqs_subscription}
                                    />
                                </div>
                                <div className="app-data-vi">
                                    <SearchFAQs 
                                        localSearchFaqs={localSearchFaqsvi}
                                        location={location}
                                        faqs_about_appvity={faqs_about_appvity_vi}
                                        faqs_etask={faqs_etask_vi}
                                        faqs_ehelpdesk={faqs_ehelpdesk_vi}
                                        // faqs_subscription={faqs_subscription_vi}
                                    />
                                </div>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="banner-page">
                        <Container>
                            <h1>Faqs</h1>
                            <h2>Main content Faqs</h2>
                        </Container>
                    </div> */}
                    {/* <div className="accordion-wrap">
                        <Container>
                            <Accordion>
                                {faqs.map((res) => (
                                    <Card>
                                        <Accordion.Toggle
                                            as={Card.Header}
                                            eventKey={res.id}
                                        >
                                            {res.frontmatter.title}
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={res.id}>
                                            <Card.Body>
                                                <div
                                                    className="content"
                                                    dangerouslySetInnerHTML={{
                                                        __html: res.html
                                                    }}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))}
                            </Accordion>
                        </Container>
                    </div> */}
                    <div className="app-general-seciton app-online-documentation-answer" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-data-en app-title">
                                        <h2>Online documentation might have your answer</h2>
                                    </div>
                                    <div className="app-data-vi app-title">
                                        <h2>Online documentation might have your answer</h2>
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                            <div className="app-online-documentation-answer-detail">
                                <Row>
                                    <Col xs={12} md={12} lg={2}></Col>
                                    <Col xs={12} md={12} lg={8}>
                                        <Row>
                                            {faqs_onlinedoc_have_answer.map((res) => (
                                                <Col className="app-online-documentation-answer-detail-col-custom" xs={6} md={3} lg={3} key={res.id}>
                                                    <div className="app-online-documentation-answer-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} />
                                                </Col>
                                            ))}
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={12} lg={2}></Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                    {/* <div className="app-general-seciton app-related-articles-microsoft" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <div className="app-title">
                                <h2>Related Articles from Microsoft</h2>
                            </div>
                            <div className="app-related-articles-microsoft-detail">
                                <Row>
                                    {faqs_related_article_ms.map((res) => (
                                        <Col xs={12} md={12} lg={6} key={res.id}>
                                            <ul>
                                                <li dangerouslySetInnerHTML={{ __html: res.html }} />
                                            </ul>
                                        </Col>
                                    ))}
                                </Row>
                            </div>
                        </Container>
                    </div> */}
                    <div className="app-general-seciton app-you-still-have-question" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={2}></Col>
                                <Col xs={12} md={12} lg={8}>
                                    <div className="app-data-en">
                                        {faqs_you_have_question.map((res) => (
                                            <div className="app-you-still-have-question-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </div>
                                    <div className="app-data-vi">
                                        {faqs_you_have_question_vi.map((res) => (
                                            <div className="app-you-still-have-question-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                        ))}
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}></Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Layout>
        )
    }
}
  
export default Faqs;

export const query = graphql`
    query FaqsPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_about_appvity" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_about_appvity_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_about_appvity_vi" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_etask: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_etask" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_etask_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_etask_vi" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_ehelpdesk: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_ehelpdesk" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_ehelpdesk_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_ehelpdesk_vi" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_subscription: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_subscription" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_subscription_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_subscription_vi" } } }
            sort: {fields: frontmatter___createAt, order: ASC}
        ) {
            nodes {
                html
                frontmatter {
                    title
                    keyNumber
                }
                id
            }
        }
        faqs_onlinedoc_have_answer: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_onlinedoc_have_answer" } } }
        ) {
            nodes {
                html
                id
            }
        }
        faqs_related_article_ms: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_related_article_ms" } } }
        ) {
            nodes {
                html
                id
            }
        }
        faqs_you_have_question: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_you_have_question" } } }
        ) {
            nodes {
                html
                id
            }
        }
        faqs_you_have_question_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "faqs_you_have_question_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        localSearchFaqs {
            index
            store
        }
        localSearchFaqs {
            publicIndexURL
            publicStoreURL
        }
        localSearchFaqsvi {
            index
            store
        }
        localSearchFaqsvi {
            publicIndexURL
            publicStoreURL
        }
    }
`;
